:root {
  --orange: #ff6600;
}

body{
  font-family: "Lato", serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.8px;
}

.banner{
    position: relative;
    height: 100vh;
}

.banner:before{
    content: " ";
    background-image: url(./images/background.webp);
    background-size: cover;
    position: absolute;
    left: 0;
    top: -77px;
    height:100vh;
    width: 100%;
}

.banner .photo{
    width: 350px;
    height: 350px;
    mix-blend-mode: multiply;
}

.about .icon{
    width: 50px;
}

.about .card{
  -webkit-border-radius: 50px;
  border-radius: 30px;
  background: #173B45;
  -webkit-box-shadow: 12px 12px 24px #b2b8c9, -12px -12px 24px #f0f8ff;
  box-shadow: 12px 12px 24px #b2b8c9, -12px -12px 24px #f0f8ff;
  padding: 20px 0;
  color: #F6F4F4;
}

section:not(.banner) {
    margin-bottom: 50px;
}

.login_effect{
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    padding: 20px 20px;
}

.neomorphism{
    padding: 5px;
    outline: none;
    width: 100%;
    border: none;
    border-radius: 50px;
    background: #ffffff;
    box-shadow: inset 20px 20px 60px #ff6600, inset -20px -20px 60px #bebebe;
}

.login-nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

.form_input{
    border: none;
    border-bottom: 1px solid #f6f6f6;
    background: none;
    width: 100%;
    padding: 10px 0;

    &:focus{
        outline: none;
    }
}



/* Preloader css */
.preloader{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    z-index: 999999;
  }


  .three-body {
    --uib-speed: 0.8s;
    --uib-color: #ff6600;
    position: relative;
    display: inline-block;
    height: 100px;
    width: 100px;
    animation: spin78236 calc(var(--uib-speed) * 2.5) infinite linear;
    top: 45%;
    left: 45%;
   }
   
   .three-body__dot {
    position: absolute;
    height: 100%;
    width: 30%;
   }
   
   .three-body__dot:after {
    content: '';
    position: absolute;
    height: 0%;
    width: 100%;
    padding-bottom: 100%;
    background-color: var(--uib-color);
    border-radius: 50%;
   }
   
   .three-body__dot:nth-child(1) {
    bottom: 5%;
    left: 0;
    transform: rotate(60deg);
    transform-origin: 50% 85%;
   }
   
   .three-body__dot:nth-child(1)::after {
    bottom: 0;
    left: 0;
    animation: wobble1 var(--uib-speed) infinite ease-in-out;
    animation-delay: calc(var(--uib-speed) * -0.3);
   }
   
   .three-body__dot:nth-child(2) {
    bottom: 5%;
    right: 0;
    transform: rotate(-60deg);
    transform-origin: 50% 85%;
   }
   
   .three-body__dot:nth-child(2)::after {
    bottom: 0;
    left: 0;
    animation: wobble1 var(--uib-speed) infinite
       calc(var(--uib-speed) * -0.15) ease-in-out;
   }
   
   .three-body__dot:nth-child(3) {
    bottom: -5%;
    left: 0;
    transform: translateX(116.666%);
   }
   
   .three-body__dot:nth-child(3)::after {
    top: 0;
    left: 0;
    animation: wobble2 var(--uib-speed) infinite ease-in-out;
   }
   
   @keyframes spin78236 {
    0% {
     transform: rotate(0deg);
    }
   
    100% {
     transform: rotate(360deg);
    }
   }
   
   @keyframes wobble1 {
    0%,
     100% {
     transform: translateY(0%) scale(1);
     opacity: 1;
    }
   
    50% {
     transform: translateY(-66%) scale(0.65);
     opacity: 0.8;
    }
   }
   
   @keyframes wobble2 {
    0%,
     100% {
     transform: translateY(0%) scale(1);
     opacity: 1;
    }
   
    50% {
     transform: translateY(66%) scale(0.65);
     opacity: 0.8;
    }
   }

.spinner {
    width: 50px;
    height: 50px;
    border: 6px solid #ccc;
    border-top-color: #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to { transform: rotate(360deg); }
}



/* chatapp css */

.chat-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
  }
  
  .messages {
    flex: 1;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .input-container {
    display: flex;
  }
  
  input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
  }

  .skillImg{
    width: 100%;
  }
  